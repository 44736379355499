<template>
  <div v-if="state.detail" style="padding-bottom: 20px;">
    <div class="head-wrap"></div>
    <div class="detail-body">
      <div class="title-wrap">
        <div class="title">{{ state.detail.title }}</div>
        <!-- <div class="collect">
          <Icon name="star-o" color="#B9C6D3" />
        </div> -->
      </div>
      <!-- guide -->
      <div class="sumary-wrap" v-if="state.detail.abstract !== '' && state.detail.article_data_type === 'guide'">
        <div class="sumary">Summary</div>
        <ReadMore :line="6">
          <div>
            {{ state.detail.abstract }}
          </div>
        </ReadMore>
      </div>
      <!-- article -->
      <div class="article-wrap" v-if="state.detail.article_data_type === 'article'">
        <div v-if="keywords.length">
          <div class="label">关键词</div>
          <div class="value">{{ keywords }}</div>
        </div>
        <div v-if="state.detail.abstract !== ''">
          <div class="label">Abstract</div>
          <ReadMore :line="6">
            <div>{{ state.detail.abstract }}</div>
          </ReadMore>
        </div>
      </div>
      <!-- detail -->
      <div class="detail-wrap">
        <div class="journal" v-if="state.detail.article_data_type === 'article'">
          <div class="label">期刊</div>
          <div class="value">{{ state.detail.journal }}</div>
        </div>
        <div class="year-pmid-wrap">
          <div class="year">
            <div class="label">年份</div>
            <div class="value">{{ state.detail.publish_year }}</div>
          </div>
          <div class="pmid" v-if="state.detail.other_info.pmid">
            <div class="label">PMID</div>
            <div class="value">{{ state.detail.other_info.pmid }}</div>
          </div>
        </div>
        <div class="publish-wrap" v-if="institutes && state.detail.article_data_type === 'guide'">
          <div class="label">发布机构</div>
          <div class="value" v-for="(item, index) in institutes" :key="index">{{ item }}</div>
        </div>
        <div class="author-wrap" v-if="authors.length">
          <div class="label">作者</div>
          <div class="value">{{ authors }}</div>
          <!-- <ReadMore :line="6">
          </ReadMore> -->
        </div>
        <!-- <div class="source-wrap">
          <div class="label">来源链接</div>
          <div class="value" @click="sourceHndle(state.detail.source_url)">{{ state.detail.source_url }}</div>
        </div> -->
        <div class="url_wrap">
          <span class="pubmed" v-if="state.detail.source === 'pubmed'" @click="sourceHndle(state.detail.source_url)">Pubmed</span>
          <span class="zhiwang" v-if="state.detail.source === '知网'" @click="sourceHndle(state.detail.source_url)">中国知网</span>
          <span class="wanfang" v-if="state.detail.source === '万方医学'" @click="sourceHndle(state.detail.source_url)">万方医学</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import ReadMore from '@/components/readmore/'
import { getAticleDetail } from '@/api/'
import { computed, reactive } from 'vue'
export default {
  components: {
    ReadMore
  },
  setup () {
    // document.title = '文献详情'
    const state = reactive({
      detail: null
    })
    const route = useRoute()

    getAticleDetail(route.query.id).then(res => {
      if (res.code === 200) {
        state.detail = res.data
        state.authors = res.data.other_info.author ? res.data.other_info.authors.join(',') : ''
      }
    })

    const keywords = computed(() => {
      if (state.detail.other_info.keywords) {
        return state.detail.other_info.keywords.join(',')
      } else {
        return []
      }
    })

    const authors = computed(() => {
      if (state.detail.other_info.authors) {
        return state.detail.other_info.authors.join(',')
      } else {
        return []
      }
    })

    const institutes = computed(() => {
      if (state.detail.institute) {
        return state.detail.institute.split(',')
      } else {
        return null
      }
    })

    const sourceHndle = (url) => {
      if (url) {
        window.open(url)
      }
    }

    return {
      state,
      authors,
      institutes,
      keywords,
      sourceHndle
    }
  }
}
</script>

<style lang="less" scoped>
.head-wrap {
  height: 88px;
  background: url('../../assets/detail.png');
  background-size: contain;
}
.detail-body {
  padding: 0 15px 0 16px;
  margin-top: -44px;
  .title-wrap {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 16px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      word-break: break-all;
    }
    .collect {
      margin-top: 12px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .sumary-wrap {
    margin-top: 12px;
    padding: 20px 12px 20px 16px;
    background: #FFFFFF;
    .sumary {
      font-size: 14px;
      color: #999999;
      margin-bottom: 8px;
    }
  }
  .article-wrap {
    margin-top: 12px;
    padding: 20px 12px 12px 16px;
    background: #FFFFFF;
    .label {
      font-size: 14px;
      color: #999999;
      margin-bottom: 8px;
    }
    .value {
      margin-bottom: 8px;
      font-size: 14px;
      color: #333333;
      word-break: break-all;
    }
  }
  .detail-wrap {
    margin-top: 12px;
    padding: 20px 16px;
    background: #FFFFFF;
    border-radius: 4px;
    .journal {
      margin-bottom: 20px;
    }
    .label {
      font-size: 14px;
      color: #999999;
    }
    .value {
      margin-top: 8px;
      font-size: 14px;
      color: #333333;
      word-break: break-all;
    }
    .year-pmid-wrap {
      display: flex;
      justify-content: space-between;
    }
    .publish-wrap {
      margin-top: 20px;
    }
    .author-wrap {
      margin-top: 20px;
    }
    .source-wrap {
      margin-top: 20px;
      .value {
        color: #0C86FE;
      }
    }
  }
}
.url_wrap {
  color: #fff;
  font-size: 12px;
  margin-top: 12px;
  .pubmed {
    padding: 5px 8px;
    background: linear-gradient(135deg, #70E2F2 0%, #58C2DD 100%);
    box-shadow: 0px 2px 6px 0px #61C7D5;
    border-radius: 4px;
    cursor: pointer;
  }
  .zhiwang {
    padding: 5px 8px;
    background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
    box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
    border-radius: 4px;
  }
  .wanfang {
    padding: 5px 8px;
    background: linear-gradient(135deg, #C0B1FD 0%, #A587F3 100%);
    box-shadow: 0px 2px 6px 0px #B7A6F8;
    border-radius: 4px;
  }
  }
</style>
