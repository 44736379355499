<template>
  <div class="content-wrap">
    <div
      class="content"
      :class="{ 'show-more': !isShowMore }"
      ref="myContent"
      :style="showLines">
      <slot></slot>
    </div>
    <div
      class="showmore-wrap"
      @click="clickMoreHandle"
    >
      <div v-if="!isShowMore" class="button-wrap">
        <span>点击展开</span>
        <Icon name="arrow-down" />
      </div>
      <div v-if="isShowMore && toggle" class="button-wrap">
        <span>收起</span>
        <Icon name="arrow-up" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'
import { computed, onMounted, ref } from 'vue'
export default {
  components: {
    Icon
  },
  props: {
    line: {
      type: Number,
      default: 3
    },
    toggle: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const isShowMore = ref(false)
    const myContent = ref(null)

    onMounted(() => {
      // console.log(myContent.value.children[0].clientHeight)
    })

    const showLines = computed(() => {
      return {
        '--line': props.line
      }
    })

    const clickMoreHandle = () => {
      isShowMore.value = !isShowMore.value
    }

    return {
      myContent,
      isShowMore,
      showLines,
      clickMoreHandle
    }
  }
}
</script>

<style lang="less" scoped>
.content-wrap {
  .content {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
    word-break: break-all;
  }
  .showmore-wrap {
    margin-top: 12px;
    .button-wrap {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 5px;
      }
    }
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: #0C86FE;
    line-height: 14px;
  }
  .show-more {
    display: -webkit-box;
    -webkit-line-clamp: var(--line);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
